import { css } from '@emotion/react';
import { BreakPoint, greaterThan } from '@ridi-web/design-system/Styles/cjs';

import type { RIDITheme } from '@/components/styles/themes';

// Design Guide: https://www.figma.com/design/CPYP3bD3TbkAXWxwLPNkl6/%EB%A7%8C%ED%99%94-%EC%84%A0%EB%8F%85%EC%A0%90-%EA%B0%95%EC%A1%B0?node-id=1116-54933&m=dev

export const positionStyle = css`
  position: absolute;
  top: 0;
  left: 0;

  /* dark mode에서 색상 반전 이슈 처리 */
  color-scheme: only light;
`;

export const badgeStyle = (theme: RIDITheme) => css`
  position: relative;
  width: 47px;
  height: 24px;

  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 4px 0;

  /* inner border style */
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    border: 1px solid ${theme.colors.white};
    border-radius: inherit;
    opacity: 0.1;
  }

  ${greaterThan(
    BreakPoint.DesktopSmall,
    css`
      width: 61px;
      height: 30px;
    `,
  )}
`;

export const backgroundStyle = (theme: RIDITheme) => css`
  /* background style with opacity */
  position: absolute;
  width: 100%;
  height: 100%;

  background-color: ${theme.colors.black};
  opacity: 0.7;

  border-radius: inherit;
`;

export const iconStyle = css`
  /* common icon style in flex box */
  flex-shrink: 0;

  width: 39px;
  height: 12px;

  /* 투명도 있는 background 에 영향 받지 않기 위함 */
  z-index: 1;

  ${greaterThan(
    BreakPoint.DesktopSmall,
    css`
      width: 49px;
      height: 16px;
    `,
  )}
`;
