import { useMemo } from 'react';

import { useFeatureFlagValueByKey } from '../../../FeatureFlag';
import type { BookPreset } from '../../presets';
import { BookMonopolyBadge } from '../BookMonopolyBadge';

// TODO: [선독점 노출 강조] 실험 종료 후 hook 삭제 및 사용처의 presets 에 직접 추가
// TODO: [선독점 노출 강조] MonopolyBadge 에 옵션 및 스타일 필요시 props 추가. 뱃지 컴포넌트 자체를 받는것도 고려
export const useMonopolyBadgePresets = (presets: BookPreset[] = []) => {
  // TODO: [선독점 노출 강조] 피쳐플래그 가딩 적용
  const showMonopolyBadge = useFeatureFlagValueByKey('web-first-monopoly-20250304');

  return useMemo(() => {
    if (!showMonopolyBadge) {
      return presets;
    }

    return [
      ...presets,
      components => ({
        ...components,
        BookMonopolyBadge,
      }),
    ];
  }, [presets, showMonopolyBadge]);
};
