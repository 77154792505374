import { useContext } from 'react';

import { IconBadgeROnly } from '@/assets/svgs/system';
import { modularComponent } from '@/utils/modularComponent';

import { BookDataContext } from '../BookDataContext';
import * as styles from './BookMonopolyBadge.styles';

export const BookMonopolyBadge = modularComponent(() => ({ className }) => {
  const bookData = useContext(BookDataContext);

  const { isEntireSeriesPreExclusive, isNewEpisodePreExclusive } = bookData.metadata?.extra?.series ?? {};
  // 단권 선독점 여부: 시리즈 정보가 없거나 use_series = N 이면서 isPreExclusive = true
  const isSinglePreExclusive = !bookData.metadata?.extra?.isSeries && bookData.cover?.isPreExclusive;

  const isPreExclusive = isSinglePreExclusive || isEntireSeriesPreExclusive || isNewEpisodePreExclusive;

  if (!isPreExclusive) {
    return <></>;
  }

  const isSetBook = !!bookData.cover?.setBook?.count;

  /**
   * 세트 선독점은 ‘최저가’ 강조 목적이 높아 독점 정보 전달에서는 제외
   * @see https://www.notion.so/ridi/PRD-1932d79d770780f0a910f64925271e11?pvs=4#1992d79d770780249560cd43c05e3a6b
   */
  if (isSetBook) {
    return <></>;
  }

  return (
    <div css={styles.positionStyle} className={className} aria-label="RIDI ONLY">
      <div css={styles.badgeStyle}>
        <div css={styles.backgroundStyle} />
        <IconBadgeROnly css={styles.iconStyle} />
      </div>
    </div>
  );
});
