import styled from '@emotion/styled';
import { useContext } from 'react';

import { modularComponent } from '@/utils/modularComponent';

import { BookDefinitionContext } from '../BookDefinitionContext';
import * as styles from './BookCoverRenderer.styles';

export const BookCoverDimmer = styled.div(styles.overlayStyle);

const coverStyle = styles.coverStyle(BookCoverDimmer);

export const BookCoverRenderer = modularComponent(() => ({ className, children, index = 0 }): ReactJSX.Element => {
  const {
    BookAdultIndicator,
    BookBadgeRenderer,
    BookCoverDimmer: BookContextCoverDimmer,
    BookFreebook,
    BookLink,
    BookSetbook,
    BookOriginal,
    BookPurchaseStatus,
    BookThumbnail,
    BookAdultBadge,
    BookMonopolyBadge,
  } = useContext(BookDefinitionContext).components;

  return (
    <BookLink css={coverStyle} className={className} index={index}>
      <BookThumbnail />
      <BookAdultIndicator />
      <BookFreebook />
      <BookSetbook />
      <BookPurchaseStatus />
      <BookContextCoverDimmer />
      <BookOriginal />
      <BookBadgeRenderer />
      <BookAdultBadge />
      <BookMonopolyBadge />
      {children}
    </BookLink>
  );
});
