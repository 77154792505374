import { createContext } from 'react';

import type { EventParamsType } from '@/utils/eventClient';
import type { RDTTrackingURIParams } from '@/utils/query';

import type { BookPurchaseStatusType } from './BookCoverRenderer/BookPurchaseStatus';
import type { BookThumbnailType } from './BookCoverRenderer/BookThumbnail';
import type { BookAuthorType } from './BookMetadataRenderer/BookAuthors';

export type BookPriceType = {
  // 책의 대여 가격, serial 일 경우 0이 아닌 값중에서 가장 작은 값을 주면 된다
  minSellingPrice: 0 | number;

  // 할인 퍼센트
  discountPercentage: number;

  // 원가
  originalPrice: number;
};

// Landscape 형태의 책에서 노출되는 전권대여 시의 가격할인 표시
export type BookAllPriceType = {
  originalPrice: number;
  discountPercentage: number;
  discountPrice: number;
};

export type BookRenderData = DeepPartial<{
  // 책 ID
  bookId: string;

  // 책 제목
  title: string | null;

  // 책의 링크
  link: string | null;

  cover: {
    // 커버 좌측 상단의 뱃지
    badges: {
      // 할인율
      discount: number;

      // 대여 뱃지
      rental: boolean;

      // 기다리면 무료 뱃지
      waitFree: boolean;

      // NEW 뱃지, 신간 알림 탭과 리뉴얼 형태에서만 사용됨
      newRelease: boolean;

      // 셀렉트 뱃지
      select: boolean;

      // 무료선택 뱃지
      freeBook: boolean;

      // 서버에서 계산되어 내려온 뱃지
      image: {
        type: string;
        color: { dark: string; light: string };
        images: string[];
        alt: string;
      }[];

      // 요일웹툰 10연재 연재주기 뱃지
      schedule: string;
    };

    // 커버의 이미지, 명시된 것들 중 가장 크기가 맞는 커버를 찾아 렌더링
    thumbnail:
      | {
          [key in BookThumbnailType]?: string;
        }
      | {
          thumbnailId?: string;
        };

    // 커버 우측 하단 n화 무료 / n권 무료 영역
    freeBook: {
      count: number;

      // "화", "권", 또는 다른 구분자
      unit: string;
    };

    // 하단 n권 세트 띠
    setBook: {
      count: number;
    };

    // 성인전용 뱃지, 도서 커버 변경에 사용
    isAdultOnly: boolean;

    // 리디 오리지널 여부
    original: boolean;

    // 도서 선독점 여부
    isPreExclusive: boolean;

    extra: {
      // 마이페이지 등에서만 보이는 하단의 구매한 책 / 대여중인 책 / 대여만료된 책 영역
      purchaseStatus: BookPurchaseStatusType;
    };
  };

  metadata: {
    title: {
      // 책의 제목을 보일지, 기본 값은 참
      showTitle: boolean;
    };

    // 작가의 배열
    authors: BookAuthorType[];

    // 별점
    starRate: {
      // 별점 평균 점수 (0~5점)
      score: number;

      // 별점을 남긴 유저 수
      count: number;
    };

    // 특정 상황 (Landscape 등)에서만 나오거나 주로 사용되지 않는 데이터
    extra: {
      // 가격
      price: {
        rental: BookPriceType;

        setbookTotalRental: BookAllPriceType;

        purchase: BookPriceType;

        setbookTotalPurchase: BookAllPriceType;
      };

      // 책 설명
      description: string;

      // 출판사
      publisher: string;

      // 장르 (로맨스 e북, 로맨스 웹소설, 웹툰, 라이트노벨, 소설 등등)
      genre: string;

      // 시리즈 정보
      series: {
        // 총 n화
        total: number;

        // 권 | 화
        unit: string;

        // 완결여부
        completion: boolean;

        // 시리즈 전체 선독점 여부
        isEntireSeriesPreExclusive: boolean;

        // 최신 업데이트 선독점 여부
        isNewEpisodePreExclusive: boolean;
      };

      // 시리즈 도서로 보여줄지 여부
      isSeries: boolean;

      // 제목 위에 나타나는 뱃지, 리뉴얼 형태에서만 주로 사용됨
      titleBadge: {
        // 비공제 도서, 현재는 거의 사용되지 않는 뱃지
        isDeductionUnavailable: boolean;

        // 썸딜이 적용되는 도서, 현재는 거의 사용되지 않는 뱃지
        isSomedeal: boolean;
      };

      style: {
        layout: string;
      };
    };
  };
}>;

export type BookTrackingOptions = {
  trackingOptions?: {
    disableTrackEvent?: boolean;
    extraParams?: EventParamsType;
    rdtTrackingURIParams?: Pick<RDTTrackingURIParams, 'sectionArg'>;
  };
};

export const BookDataContext = createContext<BookRenderData & BookTrackingOptions>({});
